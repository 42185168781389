<template lang="pug">
b-card.col-12.mb-1
  .header-row.mb-3
    .header-title RSS匯出項目列表
    b-button.width-lg(
      variant="primary" 
      v-b-modal.modal-rss
      @click="setNullRssItem"
    )                         
      span 新增匯出資料 

  //- Table
  .table-responsive.mb-0
    b-table(
      ref="RssExportList"
      responsive,
      :items="getRssExportList",
      :fields="RssExportListFields", 
      :per-page="perPage", 
      :current-page="currentPage", 
      :filter-included-fields="filterOn", 
      @filtered="onFiltered"
    )
      template(v-slot:cell(export_type)="row")
        span(v-if="row.item.export_type == 1") 分類
        span(v-if="row.item.export_type == 2") TAG

      template(v-slot:cell(news_types)="row")
        span(v-if="!row.item.news_types.main") -
        span(v-if="row.item.news_types") {{ row.item.news_types.main.name }}
        span(v-if="row.item.news_types") / {{ row.item.news_types.sub.name }}

      template(v-slot:cell(tag_id)="row")
        sapn(v-if="row.item.tag") {{ row.item.tag.name }}
        span(v-else) -

      template(v-slot:cell(status)="row")
        span(v-if="row.item.status == 0") 關閉
        span(v-if="row.item.status == 1") 啟用

      template(v-slot:cell(link_status)="row")
        span(v-if="row.item.link_status == 0") 已過期
        span(v-if="row.item.link_status == 1") 未過期

      template(v-slot:cell(end_date)="row")
        span(v-if="row.item.end_date") 
          | {{ row.item.end_date }} 
          br
          | {{ row.item.end_time }}
        span(v-else) -

      template(v-slot:cell(trigger_type)="row")
        span(v-if="row.item.trigger_type == 1") 1小時
        span(v-if="row.item.trigger_type == 2") 2小時
        span(v-if="row.item.trigger_type == 3") 3小時
        span(v-if="row.item.trigger_type == 4") 6小時
        span(v-if="row.item.trigger_type == 5") 12小時
        span(v-if="row.item.trigger_type == 6") 24小時

      template(v-slot:cell(updated_at)="row")
        sapn 
          | {{ $root.common.getNowTime('date',row.item.updated_at) }}
          br
          | {{ $root.common.getNowTime('time',row.item.updated_at) }}

      template(v-slot:cell(editer)="row")
        span {{ row.item.editer.name }}

      template(v-slot:cell(edit)="row")
        b-button.table-btn.mr-2(
          v-b-modal.modal-rss
          @click="setEidtRssItem(row.item)"
        ) 編輯

        b-button.table-btn(
          @click="deleteRssItem(row.item.id)"
        ) 刪除

  .row.mb-md-2
    .col-12
      div(class="dataTables_paginate paging_simple_numbers float-right")
        ul.pagination.pagination-rounded.mb-0
          //- pagination
          b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")

  b-modal#modal-rss(
    :title="(RssItem.id) ? '編輯 RSS 項目':'增加 RSS 項目'", 
    title-class="font-18", 
    body-class="p-4"
    @ok="putRssItem"
  )
    .row(v-if="RssItem.can_edit")
      .col-md-12
        .form-group
          label 廠商名稱
          input.form-control(type="text", placeholder="請輸入廠商名稱，限制20個字",v-model="RssItem.trade_names")

      .col-md-12
        .form-group
          label 匯出類型
          select.form-control(v-model="RssItem.export_type")
            option(value="") 請選擇區域
            option(value="1") 新聞分類
            option(value="2") TAG

      .col-md-12(v-if="RssItem.export_type == 1")
        .form-group.mb-2
          label 新聞類別
          br
          .row
            .col
              multiselect(
                key="NewsMainType"
                track-by="id",
                label="name",
                v-model="RssItemMainType",
                :options="$root.newsTypeList",
                placeholder="選擇主分類",
                select-label=""
              )
                template(slot="singleLabel", slot-scope="{ option }")
                  strong {{ option.name }}

            .col-6(v-if="RssItemMainType.id")
              multiselect(
                key="NewsSubType"
                track-by="id",
                label="name",
                v-model="RssItemSubType",
                :options="RssItemMainType.sub_types",
                placeholder="選擇次分類",
                select-label=""
              )

      .col-md-12(v-if="RssItem.export_type == 2")
        .form-group.mb-2
          label TAG
          br
          multiselect(
            v-model="RssItem.tag",
            :options="TagOptions",
            select-label="",
            label="name",
            track-by="id",
            placeholder="請選擇TAG",
            @search-change="searchTagList"
          )

      .col-md-12
        .form-group
          label 供稿數量
          input.form-control(type="text", placeholder="請輸入輸出的新聞稿件數量",v-model="RssItem.news_cnt")

      .col-md-12
        .form-group.mb-2
          label 定時觸發
          br
          select.form-control(v-model="RssItem.trigger_type")
            option(value="") 請選擇定時觸發間距
            option(value="1") 1小時
            option(value="2") 2小時
            option(value="3") 3小時
            option(value="4") 6小時
            option(value="5") 12小時
            option(value="6") 24小時

      .col-md-12
        .form-group
          label 
            span 到期時間
            .control
            b-form-checkbox(
              v-model="RssItem.unlimit",
              name="checkbox",
              value="accepted",
              format="yyyy-MM-dd HH:mm:ss"
              unchecked-value="not_accepted"
              @change="resetEndDate"
            ) 無到期日
          date-picker(
            type="datetime"
            ref="downDate"
            :is-24="true"
            v-model="RssItem.end_at"
            format="YYYY-MM-DD HH:mm"
            placeholder="請選擇到期時間"
            :disabled="RssItem.unlimit === 'accepted'"
            :ref="'RssItemEndDateTime-' + RssItem.id"
            :key="'RssItemEndDateTime-' + RssItem.id"
            @change="resetEndDate"
          )

      .col-md-12
        .form-group.mb-2
          label 到期說明
          br
          textarea.form-control(
            v-model="RssItem.des",
            rows="3",
            type="text",
            placeholder="說明"
          )

      .col-md-12
        .form-group.mb-2
          label 啟用狀態
          br
          select.form-control(v-model="RssItem.status" placeholder="請選擇 RSS 項目是否啟用")
            option(:value="false") 關閉
            option(:value="true") 啟用

      .col-md-12(v-if="RssItem.link")
        .form-group.mb-0
          label RSS連結
          input.form-control(type="text", readonly, placeholder="請輸入RSS連結",:value="RssItem.link")



</template>
<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
export default {
  name: "RssExportDataList",
  props: [
    'currentPageSearchOptions'
  ],
  components: {
    Multiselect,
    DatePicker
  },
  watch: {
    currentPageSearchOptions () {
      this.reloadDataList()
    }
  },
  data () {
    return {
      perPage: 25,
      totalRows: 0,
      currentPage: 1,
      tableData: [],
      filterOn: [],
      TagOptions: [],
      // 目前編修的項目
      RssItem: false,
      RssItemMainType: false,
      RssItemSubType: false,
      // RSS 列表欄位清單
      RssExportListFields: [
        { key: "trade_names", label: '廠商名稱', sortable: false, class: 'width-auto', },
        { key: "export_type", label: '匯出類型', sortable: false, class: 'width-auto center', },
        { key: "news_types", label: '新聞分類', sortable: false, class: 'width-auto center', },
        { key: "tag_id", label: 'TAG', sortable: false, class: 'width-auto center' },
        { key: "news_cnt", label: '供稿數量', sortable: false, class: "width-auto center" },
        { key: "trigger_type", label: '更新時間', sortable: false, class: 'width-auto center', },
        { key: "link", label: 'RSS連結', sortable: false, class: 'width-auto', },
        { key: "end_date", label: '終止日期', sortable: false, class: 'width-auto center', },
        { key: "updated_at", label: '最近更新時間', sortable: false, class: 'width-auto center', },
        { key: "editer", label: '更新人員', sortable: false, class: 'width-auto center', },
        { key: "link_status", label: '到期', sortable: false, class: 'width-auto center', stickyColumn: true },
        { key: "status", label: '啟用', sortable: false, class: 'width-auto center', stickyColumn: true },
        { key: "edit", label: '操作', sortable: false, class: 'width-auto center', stickyColumn: true },
      ],
    }
  },
  methods: {
    onFiltered (filteredItems) {
      this.currentPage = 1;
    },
    reloadDataList () {
      this.$refs.RssExportList.refresh()
    },
    // 搜尋符合的標籤
    searchTagList (_text) {
      let vm = this
      console.log("=> 標籤搜尋:", _text)
      this.$root.apis.getFastTagList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.TagOptions = _response.body.data.items
      })
    },
    // 請求獲得 RSS 匯出項目清單
    async getRssExportList (_table, _callback) {
      let vm = this
      let search = this.currentPageSearchOptions
      search.page = this.currentPage

      await this.$root.apis.getRssExportList(search, async function (_response) {
        let response = _response.body.data
        vm.totalRows = response.total
        vm.currentPage = response.page
        vm.perPage = response.per_page

        let items = response.items
        await response.items.forEach(async function (_item, _index) {
          // 處理對應標籤
          items[_index].tag = false
          if (_item.tag_id) {
            await vm.$root.apis.getFastTagList({ keys: _item.tag_id }, async function (_response) {
              items[_index].tag = await _response.body.data.items[0]
            })
          }
          // 處理新聞分類
          items[_index].news_types = false
          if (_item.main_type && _item.sub_type) {
            await vm.$root.common.searchNewsTypeByID(_item.main_type, _item.sub_type, async function (_typeInfo) {
              items[_index].news_types = await _typeInfo
            })
          }
          // 處理到期時間
          items[_index].end_at = (_item.end_date) ? vm.$root.common.getNowTime('Object', _item.end_date + " " + _item.end_time) : null
        })

        if (typeof _callback === 'function') {
          await _callback(items)
        }
      })
    },
    // 請求編輯 RSS 項目
    async setNullRssItem () {
      this.RssItem = {
        trade_names: "",
        region_type: "",
        link: "",
        main_type: false,
        sub_type: false,
        trigger_type: "",
        end_date: null,
        end_time: null,
        des: "",
        status: false,
        unlimit: "not_accepted",
        can_edit: true
      }
    },
    async setEidtRssItem (_rssItem) {
      // 處理是否有到期日
      _rssItem.unlimit = "accepted"
      if (_rssItem.end_at) {
        _rssItem.unlimit = "not_accepted"
      }

      let vm = this
      await vm.$root.common.searchNewsTypeByID(_rssItem.main_type, _rssItem.sub_type, async function (_typeInfo) {
        vm.RssItemMainType = _typeInfo.main
        vm.RssItemSubType = _typeInfo.sub
      })

      _rssItem.can_edit = true

      // 處理傳遞資料
      this.RssItem = _rssItem
    },
    async deleteRssItem (_rssItemID) {
      if (!confirm("您確定要移除這個 RSS 項目嗎" + "\r\n" + "注意: 此操作無法復原")) {
        return
      }

      let vm = this
      this.$root.apis.delRssExportItem(_rssItemID, function (_response) {
        vm.reloadDataList()
        vm.$root.common.showSingleNotify("RSS 匯出項目刪除成功！")
      })
    },
    // 重新設定關於到期日的項目
    resetEndDate () {
      if (this.RssItem.unlimit === "accepted") {
        console.log('=> 將 RSS Link 到期日設置為空...')
        this.RssItem.end_at = null
      }
      this.$forceUpdate()
    },
    // 請求更新一個 RSS 項目
    putRssItem () {
      let vm = this
      let item = {
        trade_names: this.RssItem.trade_names,
        export_type: this.RssItem.export_type,
        link: this.RssItem.link,
        news_cnt: this.RssItem.news_cnt,
        main_type: (this.RssItemMainType) ? this.RssItemMainType.id : null,
        sub_type: (this.RssItemSubType) ? this.RssItemSubType.id : null,
        tag_id: (this.RssItem.tag) ? this.RssItem.tag.id : null,
        trigger_type: this.RssItem.trigger_type,
        end_date: (this.RssItem.end_at) ? this.$root.common.getNowTime('date', this.RssItem.end_at) : null,
        end_time: (this.RssItem.end_at) ? this.$root.common.getNowTime('time', this.RssItem.end_at) : null,
        des: this.RssItem.des,
        status: this.RssItem.status
      }

      console.log('=> 提交的表單: ', item)

      // 以更新方式設置 RSS 項目
      if (this.RssItem.id) {
        this.$root.apis.putRssExportItem(this.RssItem.id, item, function (_response) {
          vm.reloadDataList()
          vm.$root.common.showSingleNotify("RSS 匯出項目更新成功！")
        })
      }

      // 以建立方式設置 RSS 項目
      else {
        this.$root.apis.createRssExportItem(item, function (_response) {
          vm.reloadDataList()
          vm.$root.common.showSingleNotify("RSS 匯出項目建立成功！")
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.form-group
  gap: 15px
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
::v-deep .width-auto
  min-width: auto
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0

::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
  
</style>
