
<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button-group.tab-group
        b-button.mr-2(variant="primary" :class="{off: currentPageTab !== 1}" @click="changeCurrentTab(1)") 匯入
        b-button.mr-2(variant="primary" :class="{off: currentPageTab !== 2}" @click="changeCurrentTab(2)") 匯出
    .col-lg-12

      //- RSS 匯入項目清單
      RssImportSearchView(
        v-if="currentPageTab === 1"
        key="RssImportSearchView"
        :currentPageSearchVisible="currentPageSearchVisible"
        :currentPageSearchOptions="currentPageSearchOptions"
        @setSearchOptions="setSearchOptions"
      )

      //- RSS 匯入項目列表
      RssImportListTable(
        v-if="currentPageTab === 1"
        key="RssImportListTable"
        :currentPageSearchOptions="currentPageSearchOptions"        
      )

      //- RSS 匯出項目清單
      RssExportSearchView(
        v-if="currentPageTab === 2"
        key="RssExportSearchView"
        :currentPageSearchVisible="currentPageSearchVisible"
        :currentPageSearchOptions="currentPageSearchOptions"
        @setSearchOptions="setSearchOptions"
      )

      //- RSS 匯出項目列表
      RssExportListTable(
        v-if="currentPageTab === 2"
        key="RssExportListTable"
        :currentPageSearchOptions="currentPageSearchOptions"        
      )
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Switches from "vue-switches";
import RssImportSearchView from "./RssSearchView/import.vue"
import RssExportSearchView from "./RssSearchView/export.vue"
import RssImportListTable from "./RssListTable/import.vue"
import RssExportListTable from "./RssListTable/export.vue"

/**
 * Starter component
 */
export default {
  name: "RssSettingList",
  components: {
    Layout,
    PageHeader,
    Switches,
    RssImportSearchView,
    RssExportSearchView,
    RssImportListTable,
    RssExportListTable
  },
  data () {
    return {
      // 本頁基本參數
      title: "RSS 項目管理清單",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "RSS 設定",
          active: true,
        }
      ],
      // 本頁資料層參數
      currentPageTab: 1,
      currentPageSearchVisible: true,
      currentPageSearchOptions: {
        trade_name: ""
      }
    };
  },
  computed: {
  },
  watch: {
    currentPageTab (_tab) {
      this.currentPageSearchVisible = true
    }
  },
  methods: {
    // 變更目前所選的功能分頁
    changeCurrentTab (_status) {
      this.currentPageTab = _status
    },
    // 設定搜尋選項
    setSearchOptions (_searchOptions) {
      console.log("=> 重設搜尋選項: ", _searchOptions)
      this.currentPageSearchOptions = _searchOptions
    }
  }
};
</script>

<style lang="sass" scoped>
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.form-group
  gap: 15px
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
  
</style>
