<template lang="pug">
b-card.col-12.mb-1(no-body)
  b-card-header(header-tag="header", role="tab")
    .header-row
      .header-title 篩選查詢 
        i.fe-settings.ml-2
      a.collapse-turn.mr-2(@click="currentPageSearchVisible = !currentPageSearchVisible", href="javascript: void(0);")
        span(v-if="currentPageSearchVisible") 收起 
          i.fe-chevron-up
        span(v-else) 展開 
          i.fe-chevron-down
      b-button.ml-1.width-lg(variant="primary",@click="setSearchOptions") 查詢
      b-button.ml-1.width-lg(variant="secondary",@click="clearSearchOptions") 清除
  b-collapse#customaccordion-1(v-model="currentPageSearchVisible", accordion="accordion", role="tabpanel")
    b-card-body
      form.form-horizontal(role="form")
        .row
          .col-lg-4
            .form-group.mb-2
              label.col-form-label(for="form-control") 廠商名稱
              input.form-control(v-model="currentTradeName", type="text")
          .col-lg-4.mt-1
            .form-group.mb-2
              label 匯出類型
              multiselect(
                key="ExportType"
                track-by="id",
                label="name",
                select-label=""
                v-model="currentExportType"
                :options="ExportTypeOptions"
                placeholder="請選擇匯出類型"
              )
          .col-lg-4.mt-1
            .form-group.mb-2
              label 新聞類別
              br
              .row
                .col
                  multiselect(
                    key="NewsMainType"
                    track-by="id",
                    label="name",
                    v-model="currentMainType",
                    :options="$root.newsTypeList",
                    placeholder="選擇主分類",
                    select-label=""
                  )
                    template(slot="singleLabel", slot-scope="{ option }")
                      strong {{ option.name }}

                .col-6(v-if="currentMainType.id")
                  multiselect(
                    key="NewsSubType"
                    track-by="id",
                    label="name",
                    v-model="currentSubType",
                    :options="currentMainType.sub_types",
                    placeholder="選擇次分類",
                    select-label=""
                  )
        .row
          .col-lg-4.mt-1
            .form-group.mb-2
              label TAG
              multiselect(
                v-model="currentTag",
                :options="TagOptions",
                select-label="",
                label="name",
                track-by="id",
                placeholder="請選擇TAG",
                @search-change="searchTagList"
              )
          .col-lg-4.mt-1
            .form-group.mb-2
              label 更新時間
              multiselect(
                key="TiggerType"
                track-by="id",
                label="name",
                v-model="currentTiggerType"
                :options="TiggerOptions", 
                placeholder="請選擇更新時間間隔", 
                select-label=""
              )
          .col-lg-4.mt-1
            .form-group.mb-2
              label 終止狀態
              multiselect(
                key="EndStatusType"
                track-by="id",
                label="name",
                v-model="currentEndStatus"
                :options="EndStatusOptions", 
                placeholder="請選擇終止狀態", 
                select-label=""
              )
        .row
          .col-lg-4.pl-0 
            .form-group.col
              label.col-form-label(for="example-date") 終止時間區間
              date-picker(
                v-model="currentEndDateRange",
                range,
                append-to-body,
                lang="en",
                confirm,
                format="YYYY-MM-DD",
                placeholder="請選擇日期區間"
              )
          .col-lg-4.mt-1
            .form-group.mb-2
              label 更新人員
              multiselect(
                v-model="currentManager",
                :options="ManagerOptions",
                placeholder="請選擇人員",
                label="name",
                track-by="id",
                @search-change="searchManagerList",
                select-label=""
              )
          .col-lg-4.pl-0
            .form-group.col
              label.col-form-label(for="example-date") 更新日期
              date-picker(
                v-model="currentPutDateRange",
                range,
                append-to-body,
                lang="en",
                confirm,
                format="YYYY-MM-DD",
                placeholder="請選擇日期區間"
              )
        .row
          .col-lg-4
            .form-group.mb-2
              label.col-form-label(for="form-control") RSS連結
              input.form-control(v-model="currentLinkString", type="text",placeholder="請輸入RSS連結")

</template>
<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
export default {
  name: "RssExportSearchView",
  props: [
    'currentPageSearchVisible',
    'currentPageSearchOptions'
  ],
  components: {
    Multiselect,
    DatePicker,
  },
  data () {
    return {
      SearchOptions: false,
      // 選擇內容
      currentTradeName: "",
      currentExportType: false,
      currentMainType: false,
      currentSubType: false,
      currentStatus: false,
      currentTiggerType: false,
      currentEndStatus: false,
      currentEndDateRange: false,
      currentPutDateRange: false,
      currentManager: false,
      currentTag: false,
      currentLinkString: "",
      // 選項清單
      ExportTypeOptions: [
        { id: 1, name: '分類' },
        { id: 2, name: 'TAG' }
      ],
      StatusOptions: [
        { id: 1, name: '啟用' },
        { id: 2, name: '關閉' }
      ],
      TiggerOptions: [
        { id: 1, name: "1小時" },
        { id: 2, name: "2小時" },
        { id: 3, name: "3小時" },
        { id: 4, name: "6小時" },
        { id: 5, name: "12小時" },
        { id: 6, name: "24小時" },
      ],
      EndStatusOptions: [
        { id: 1, name: "啟用中" },
        { id: 2, name: "已到期" }
      ],
      ManagerOptions: [],
      TagOptions: []
    }
  },
  create () {
    this.SearchOptions = this.currentPageSearchOptions
  },
  methods: {
    // 搜尋符合的標籤
    searchTagList (_text) {
      let vm = this
      console.log("=> 標籤搜尋:", _text)
      this.$root.apis.getFastTagList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.TagOptions = _response.body.data.items
      })
    },
    // 搜尋管理員名單
    searchManagerList (_text) {
      let vm = this
      console.log("=> 管理員搜尋:", _text)
      this.$root.apis.getFastManagerList({
        name: _text,
        per_page: 100
      }, function (_response) {
        vm.ManagerOptions = _response.body.data.items
      })
    },
    // 清空搜尋選項參數
    clearSearchOptions () {
      this.currentTradeName = ""
      this.currentExportType = false
      this.currentMainType = false
      this.currentSubType = false
      this.currentTag = false
      this.currentStatus = false
      this.currentEndStatus = false
      this.currentTiggerType = null
      this.currentEndDateRange = null
      this.currentEndDateRange = null
      this.currentManager = false
      this.currentLinkString = ""

      this.setSearchOptions()
    },
    // 更新搜尋選項參數
    setSearchOptions () {
      let SearchOptions = {
        trade_names: this.currentTradeName,
        export_type: (this.currentExportType) ? this.currentExportType.id : null,
        main_type: (this.currentMainType) ? this.currentMainType.id : null,
        sub_type: (this.currentSubType) ? this.currentSubType.id : null,
        tag_id: (this.currentTag) ? this.currentTag.id : null,
        status: this.currentStatus,
        link_status: this.currentEndStatus,
        trigger_type: this.currentTiggerType,
        end_startdate: (this.currentEndDateRange) ? this.currentEndDateRange[0] : null,
        end_enddate: (this.currentEndDateRange) ? this.currentEndDateRange[1] : null,
        editer_userId: (this.currentManager) ? this.currentManager.id : null,
        link: this.currentLinkString
      }

      this.$emit('setSearchOptions', SearchOptions)
    }
  }
}
</script>

<style lang="sass" scoped>
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.form-group
  gap: 15px
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
  
</style>
